import { http } from '@/app/helpers/axios-instance';

export const fetchPaymentById = id => {
  return http.get(`/v1/payments/${id}`).then(response => response.data);
};

export const refundPaymentByUuid = ({ uuid, amount = 0.0 }) => {
  const refundRequest = {
    amount: amount,
    source: 'CUSTOMER_SUPPORT',
  };
  return http.post(`/v1/payments/${uuid}/refund`, refundRequest).then(response => response.data);
};
