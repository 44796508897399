import { useUrl } from '@/app/router';
import { ref } from 'vue';
import { isFunction } from 'lodash-es';

export const useDetails = ({
  fetchFunction = null,
  updateFunction = null,
  id = useUrl().id,
} = {}) => {
  const isFetching = ref(false);
  const isSaving = ref(false);

  const beforeFetch = () => {
    isFetching.value = true;
  };

  const beforeSave = () => {
    isSaving.value = true;
  };

  const afterRequests = () => {
    isSaving.value = false;
    isFetching.value = false;
  };

  const loadComponentData = () => {
    if (!fetchFunction) {
      // eslint-disable-next-line
      console.error('fetchFunction is not defined');
      return new Promise(resolve => resolve({}));
    }

    if (id.value === '0') {
      return new Promise(resolve => resolve({}));
    }

    beforeFetch();

    return fetchFunction(id.value).finally(afterRequests);
  };

  const updateData = data => {
    if (!updateFunction || !isFunction(updateFunction)) {
      // eslint-disable-next-line
      console.error('fetchFunction is not defined');
      return new Promise(resolve => resolve({}));
    }

    beforeSave();
    return updateFunction(data).finally(afterRequests);
  };

  const saveComponentData = async data => {
    const response = await updateData(data);

    return {
      response,
    };
  };

  return {
    loadComponentData,
    saveComponentData,
    isFetching,
    id,
    isSaving,
  };
};
