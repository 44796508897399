<template>
  <div class="container-fluid align-left">
    <div class="top-container-wrapper">
      <p class="list-title">Payment Details</p>
    </div>
    <div class="buttons-wrapper">
      <NumberInput
        v-model="amountToRefund"
        :v="{ value: amountToRefund }"
        label="Amount to refund"
      />
      <Button
        type="button"
        color="caution"
        :disabled="
          isFetching || !fields || fields.amount <= fields.refundedAmount
        "
        @click="show()"
        >Refund</Button
      >

      <Button type="button" color="light" @click="redirectBack()">Back</Button>
    </div>
    <div v-if="!isFetching && fields && fields.id" class="entity-fields">
      <div>
        <p class="entity-field-label">
          <strong>ID :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.id }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>UUID :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.uuid }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Subscription :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.subscriptionDto.id }} / {{ fields.subscriptionDto.uuid }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>CreatedAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.createdAt }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Category :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.category }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Amount :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.amount }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Currency :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.currencyDto.code }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>ExtTransactionId :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.extTransactionId }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>AdditionalId :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.additionalId }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Fraud :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.fraud }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>FraudAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.fraudAt }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>RefundedAmount :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.refundedAmount }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>RefundsCount :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.refundsCount }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>LastRefundedAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.lastRefundedAt }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>RefundSource :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.refundSource }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>Chargeback :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.chargeback }}
        </p>
      </div>
      <div>
        <p class="entity-field-label">
          <strong>ChargebackAt :</strong>
        </p>
        <p class="entity-field-value">
          {{ fields.chargebackAt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useDetails } from '@/app/use/views';
import { fetchPaymentById, refundPaymentByUuid } from '@/app/api/payments';
import { redirectToRoute } from '@/app/router';
import { ref } from 'vue';
import Button from '@/app/components/base/Button';
import NumberInput from '@/app/components/base/NumberInput';
import { useModal } from '@/app/use/modal';
import { success as showSuccessSnackbar } from '@/app/use/snackbar';
import router from '@/app/router/index';
import { useLoadingModal } from '@/app/use/loadingModal';

export default {
  name: 'PaymentDetails',
  components: { Button, NumberInput },
  setup() {
    const { id, loadComponentData, isFetching } = useDetails({
      fetchFunction: fetchPaymentById,
    });

    let fields = ref(null);
    loadComponentData().then(async data => {
      fields.value = data;
    });

    const { show } = useModal({
      onAccept() {
        useLoadingModal().showLoading();
        refundPaymentByUuid({
          uuid: fields.value.uuid,
          amount: amountToRefund.value,
        })
          .then(() => {
            showSuccessSnackbar('Refund successfully performed');
            loadComponentData().then(async data => {
              fields.value = data;
            });
          })
          .finally(() => {
            useLoadingModal().hideLoading();
          });
      },
      header: 'Confirm',
      body: 'Are you sure you want to perform refund with given amount?',
    });

    const redirectBack = () => router.go(-1);

    const amountToRefund = ref(null);

    return {
      id,
      isFetching,
      fields,
      redirectToRoute,
      show,
      redirectBack,
      amountToRefund,
    };
  },
};
</script>

<style scoped lang="scss">
.input-wrapper {
  width: 200px;
  max-width: 100%;
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.list-title {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
}

.top-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0rem 0;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
</style>
