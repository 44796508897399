<template>
  <button v-bind="$attrs" :class="[color, { 'loading-animation': isLoading }]">
    <slot>Default</slot>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="scss">
button {
  background: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(224, 224, 230);
  color: rgb(51, 54, 57);
  font-size: 1rem;
  border-radius: 3px;
  position: relative;
  display: flex;
  margin: 1rem 0;
  align-items: center;
  justify-content: space-around;

  &.success {
    background-color: #33b542;
    color: #fff;

    &:hover:not([disabled]) {
      background-color: #19bf2c;
    }
  }

  &.light {
    background-color: #f8f9fa;
    color: #343a40;

    &:hover:not([disabled]) {
      background-color: #c7c7c7;
    }
  }

  &.success-bordered {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);

    &:hover:not([disabled]) {
      background-color: var(--secondary-color);
      border: 2px solid var(--secondary-color);
      color: #fff;
    }
  }

  &.caution {
    background-color: #ff3333;
    color: #fff;

    &:hover:not([disabled]) {
      background-color: #ff0000;
    }
  }

  &.warning {
    background-color: #ff9b17;
    color: #fff;

    &:hover:not([disabled]) {
      background-color: #ff9100;
    }
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
}

.loading-animation::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 3px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
